import {NgModule} from '@angular/core';
import {LazyLoaderComponent} from "./lazy-loader.component";
import {CommonModule} from "@angular/common";
import {LoadingIndicatorModule} from "../loading-indicator/loading-indicator.module";


@NgModule({
  imports: [
    CommonModule,
    LoadingIndicatorModule
  ],
  declarations: [LazyLoaderComponent],
  exports: [LazyLoaderComponent],
})
export class LazyLoaderModule {
}
