import {Component, ElementRef, ExperimentalPendingTasks, inject, Input, OnChanges, ViewChild, ViewContainerRef} from '@angular/core';
import {AppConfig} from "../config/app-config";
import {NavigationService} from "../../../cms/navigation.service";

@Component({
  selector: 'lazy-loader',
  template: '<loading-indicator *ngIf="loading" class="block mx-auto my-auto" type="spinner"></loading-indicator><div #widget></div>',
  host: {'class': 'flex'}
})
export class LazyLoaderComponent implements OnChanges {

  private loaders: any = {
    'a11y-check': () => import('../../widgets/a11y-check/a11y-check.component').then(m => m.A11yCheckComponent),
    'backup-performance': () => import('../../widgets/backup-performance/backup-performance.component').then(m => m.BackupPerformanceComponent),
    'browser-check': () => import('../../widgets/browser-check/browser-check.component').then(m => m.BrowserCheckComponent),
    'businessaccount-pricing': () => import('../../widgets/businessaccount-pricing/businessaccount-pricing.component').then(m => m.BusinessaccountPricingComponent),
    'censorship': () => import('../../widgets/censorship/censorship.component').then(m => m.CensorshipComponent),
    'censorship-stats': () => import('../../widgets/censorship/censorship-stats.component').then(m => m.CensorshipStatsComponent),
    //'charts': () => import('../../widgets/charts/charts.component').then(m => m.ChartsComponent),
    'code': () => import('../../elements/code-highlight/code-highlight.component').then(m => m.CodeHighlightComponent),
    'dns-check': () => import('../../widgets/dns-check/dns-check.component').then(m => m.DnsCheckComponent),
    'email-check': () => import('../../widgets/email-check/email-check.component').then(m => m.EmailCheckComponent),
    'email-finder': () => import('../../widgets/email-finder/email-finder.component').then(m => m.EmailFinderComponent),
    'email-marketing': () => import('../../widgets/email-marketing/email-marketing.component').then(m => m.EmailMarketingComponent),
    'email-verification': () => import('../../widgets/email-verification/email-verification.component').then(m => m.EmailVerificationComponent),
    'external-widgets': () => import('../../widgets/external-widgets/external-widgets.component').then(m => m.ExternalWidgetsComponent),
    'etf-broker': () => import('../../widgets/etf/etf-broker/etf-broker.component').then(m => m.EtfBrokerComponent),
    'etf-calculator': () => import('../../widgets/etf/etf-calculator/etf-calculator.component').then(m => m.EtfCalculatorComponent),
    'etf-data': () => import('../../widgets/etf/etf-data/etf-data.component').then(m => m.EtfDataComponent),
    'etf-list': () => import('../../widgets/etf/etf-list/etf-list.component').then(m => m.EtfListComponent),
    'etf-stats': () => import('../../widgets/etf/etf-stats/etf-stats.component').then(m => m.EtfStatsComponent),
    'feedbax': () => import('../../widgets/feedbax/feedbax.component').then(m => m.FeedbaxComponent),
    'fingerprint-evaluation': () => import('../../widgets/browser-check/fingerprintevaluation/fingerprint-evaluation.component').then(m => m.FingerprintEvaluationComponent),
    'hosting-performance': () => import('../../widgets/hosting-performance/hosting-performance.component').then(m => m.HostingPerformanceComponent),
    'logo-maker': () => import('../../widgets/logo-maker/logo-maker.component').then(m => m.LogoMakerComponent),
    'inpaint': () => import('../../widgets/inpaint/inpaint.component').then(m => m.InpaintComponent),
    'meeting': () => import('../../widgets/meeting/meeting.component').then(m => m.MeetingComponent),
    'mobile-friendly': () => import('../../widgets/mobile-friendly/mobile-friendly.component').then(m => m.MobileFriendlyComponent),
    'pagespeed': () => import('../../widgets/pagespeed/pagespeed.component').then(m => m.PagespeedComponent),
    'page-experience': () => import('../../widgets/page-experience/page-experience.component').then(m => m.PageExperienceComponent),
    'password-check': () => import('../../widgets/password-check/password-check.component').then(m => m.PasswordCheckComponent),
    'password-generator': () => import('../../widgets/password-generator/password-generator.component').then(m => m.PasswordGeneratorComponent),
    'proxies': () => import('../../widgets/proxies/proxies.component').then(m => m.ProxiesComponent),
    'product-features': () => import('../../widgets/product-features/product-features.component').then(m => m.ProductFeaturesComponent),
    'photo-editor': () => import('../../widgets/image-editor/image-editor.component').then(m => m.ImageEditorComponent),
    'readability': () => import('../../widgets/readability/readability.component').then(m => m.ReadabilityComponent),
    'remove-bg': () => import('../../widgets/remove-bg/remove-bg.component').then(m => m.RemoveBgComponent),
    'security-check': () => import('../../widgets/security-check/security-check.component').then(m => m.SecurityCheckComponent),
    'serp-analyzer': () => import('../../widgets/serp-analyzer/serp-analyzer.component').then(m => m.SerpAnalyzerComponent),
    'server-performance': () => import('../../widgets/server-performance/server-performance.component').then(m => m.ServerPerformanceComponent),
    'spam-checker': () => import('../../widgets/spam-checker/spam-checker.component').then(m => m.SpamCheckerComponent),
    'ssl-check': () => import('../../widgets/ssl-check/ssl-check.component').then(m => m.SSLCheckComponent),
    'structured-data': () => import('../../widgets/structured-data/structured-data.component').then(m => m.StructuredDataComponent),
    'technology': () => import('../../widgets/technology/technology.component').then(m => m.TechnologyComponent),
    'text-analysis': () => import('../../widgets/text-analysis/text-analysis.component').then(m => m.TextAnalysisComponent),
    'vat-check': () => import('../../widgets/vat-check/vat-check.component').then(m => m.VatCheckComponent),
    'vpn-leak': () => import('../../widgets/vpn-leak/vpn-leak.component').then(m => m.VpnLeakComponent),
    'vpn-speed': () => import('../../widgets/vpn-speed/vpn-speed.component').then(m => m.VpnSpeedComponent)
  };

  private ssr = ['a11y-check', 'backup-performance', 'browser-check', 'businessaccount-pricing', 'censorship', 'code', 'email-check', 'email-marketing', 'email-verification', 'email-finder',
    'product-features', 'etf-calculator', 'etf-data', 'etf-list', 'etf-stats', 'hosting-performance', 'logo-maker', 'inpaint', 'meeting', 'mobile-friendly', 'pagespeed', 'page-experience', 'password-check', 'readability', 'remove-bg', 'server-performance', 'vat-check', 'vpn-speed', 'webhosting-speed'];


  @Input() component: string;
  @Input() data: any;

  @ViewChild('widget', {read: ViewContainerRef, static: true}) widget: ViewContainerRef;

  loading = false;

  private taskService = inject(ExperimentalPendingTasks);


  constructor(private config: AppConfig, private el: ElementRef, private navigation: NavigationService) {
  }


  ngOnChanges(): void {
    let loader = this.loaders[this.component];
    if (!loader)
      return;


    if (!this.ssr.includes(this.component) || this.navigation.navigationDepth > 1) {
      this.loading = true;
      if (this.config.isServer)
        return;
    }


    const taskCleanup = this.taskService.add(); // https://angular.dev/guide/experimental/zoneless#experimentalpendingtasks-for-server-side-rendering-ssr
    loader().then(componentFactory => {
      const componentRef = this.widget.createComponent(componentFactory, {index: 0});
      this.updateComponent(componentRef);
      taskCleanup();
    });
  }


  private updateComponent(componentRef) {

    this.loading = false;

    // set data
    if (Array.isArray(this.data)) {
      for (let d of this.data) {
        componentRef.instance[d.key] = d.value;
      }
    } else {
      for (let d in this.data) {
        componentRef.instance[d] = this.data[d];
      }
    }

  }
}
